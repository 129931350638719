import * as React from 'react';
import { Box, Grid, Typography, Rating } from "@mui/material";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Lazy, EffectCoverflow} from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/lazy";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import "swiper/css/effect-coverflow";

import "./../index.css";
import moment from 'moment';

const smallText = {
  fontSize: ".6em",
};
  
export default function SnapEntry(props) {
    const { entry } = props;
    
    return (
      <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid sm={12} md={12} item>
              <Typography variant="h5">{entry.name}</Typography>
              <div style={smallText}>
              {entry.wiki === null || entry.wiki === "" ? entry.species : <a href={entry.wiki}>{entry.species}</a>}
                
              </div>
          </Grid>

          <Grid sm={12} xs={12} md={12} display="flex" justifyContent="center" item >
            <Swiper
                modules={[Lazy, Navigation, Pagination, EffectCoverflow ]}
                slidesPerView={"auto"}
                spaceBetween={5}
                pagination={{
                  clickable: true
                }}
                preloadImages = {false}
                lazy={true} 
                navigation
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                
              >
                  {entry.urlArr.map(url => {
                    return (
                      <SwiperSlide key={url}>
                        <Box
                          component="img"
                          sx={{
                            maxHeight: { xs: 300, md: 700 },
                            maxWidth: { xs: 300, md: 700 },
                          }}
                          alt=""
                          src={url === null || url === "" ? "https://i.imgur.com/52hfGQd.png" : url}
                        /> 
                      </SwiperSlide> 
                    );
                  })}
              </Swiper>
          </Grid>
          
          <Grid sm={6} xs={6} md={6} item>
              <Typography paragraph>
                  {entry.notes}
              </Typography>
          </Grid>
          <Grid sm={6} xs={6} md={6} item>
              <Rating name="snap-rating" defaultValue={2.5} precision={0.5} value={entry.rating} readOnly />
              <div>
                  {moment.unix(entry.captureDt).format('YYYY/MMM/d')}
                  <br/>
                  <br/>
                  MAP
                  <br/>
                  -35, 64
              </div>
          </Grid>
          
      </Grid>
    );

}