import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App'

import Amplify from "aws-amplify";
import awsConfig from "./aws-exports";

console.log(awsConfig);

awsConfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsConfig.oauth.redirectSignOut = `${window.location.origin}/`;
awsConfig.oauth.domain = 'wildsnapaeb410ba-aeb410ba-staging.auth.us-west-1.amazoncognito.com';
awsConfig.oauth.responseType = 'code';
Amplify.configure(awsConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log(awsConfig);
