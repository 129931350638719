import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfiniteScrollView from './InfiniteScrollView';
import AppendEntry from './AppendEntry';

import WSLogo from './../img/wslogo.png';

import {ReactComponent as Ball} from './../icons/Ball.svg';
import {ReactComponent as BallGrey} from './../icons/BallGrey.svg';
import {ReactComponent as Binocular} from './../icons/Binocular.svg';
import {ReactComponent as Snap} from './../icons/Snap.svg';
import {ReactComponent as Logout} from './../icons/Logout.svg';

import { Auth } from "aws-amplify";
import Avatar from '@mui/material/Avatar';

import {  API } from 'aws-amplify';
import { listSnaps } from '../graphql/queries';

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function HomeLayout() {

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [dlg, setDialogOpen] = React.useState(false);
  const [usr, setUser] = React.useState("");
  const [snaps, setSnaps] = React.useState([]);

  useEffect(() => {
    fetchSnaps()
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const googleSignIn = async () => {
    try {
      console.log("attemptingLogin");
      Auth.federatedSignIn({ provider: "Google" });
    } catch (error) {
      console.log("error signing in", error);
    }
  };

  const signOut = async () => {
    try {
      console.log("attemptingLogout");
      Auth.signOut();
    } catch (error) {
      console.log("error attemptingLogout", error);
    }
  };

  Auth.currentAuthenticatedUser().then((user) => {
    try {
      setUser(user.attributes.email);
      if(snaps.length == 0){
        fetchSnaps()
      }
    } catch (error) {
      console.log("error signing in", error);
    }
  });

  async function fetchSnaps() {
    try {
      const snapData = await API.graphql({ query: listSnaps,
        authMode: (usr ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'),
        })
      const snaps = snapData.data.listSnaps.items
      setSnaps(snaps.sort(() => Math.random() - 0.5))
    } catch (err) { console.log(err) }
  }

  return (
    <div style={{'padding-left' : '65px',  'background-color' : '#e0ffef'} }>
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src={WSLogo} alt="WildSnap"/>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemButton
                key={"profile"}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={googleSignIn}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {<Avatar alt={usr} src="/static/images/avatar/1.jpg" />}
                </ListItemIcon>
                <ListItemText primary={usr} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              <ListItemButton
                key={"snout"}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={signOut}
                disabled = {usr ? false : true}
              >
                <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {<Logout />}
              </ListItemIcon>
                <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
        </List>
        <Divider />
        <List>
          {[(snaps.filter(function (el) {return el.captured === 1;}).length + ' Caught'), (snaps.length + ' Seen')].map((text, index) => (
            <ListItemButton
              key={text}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {index === 0 ? <Ball/> : <BallGrey />}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          ))}
        </List>
        <Divider />
        <List>
          {['Sighting', 'Catch'].map((text, index) => (
            <ListItemButton
              key={text}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={handleDialogOpen}
              disabled = {usr ? false : true}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {index % 2 === 0 ? <Binocular /> : <Snap />}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          ))}
        </List>
        
      </Drawer>
      
      <Box component="main"  sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          
          {/* SCROLLING SNAPS COMPONENT */}
          <InfiniteScrollView data={snaps}/>
      </Box>

      {/* DIALOG BOX COMPONENT */}
      <AppendEntry dlg={dlg} setDialogOpen={setDialogOpen} />

      
    </Box>
    </div>
  );
}
