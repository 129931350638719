import HomeLayout from './components/HomeLayout';
import './App.css';

function App() {

  return (
      <div>
        <HomeLayout/>
      </div>
  );
}

export default App;
