/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSnaps = /* GraphQL */ `
  query GetSnaps($id: ID!) {
    getSnaps(id: $id) {
      id
      __typename
      createdAt
      updatedAt
      name
      species
      wiki
      featured
      captured
      captureDt
      rating
      urlArr
      notes
    }
  }
`;
export const listSnaps = /* GraphQL */ `
  query ListSnaps(
    $filter: ModelSnapsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSnaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        __typename
        createdAt
        updatedAt
        name
        species
        wiki
        featured
        captured
        captureDt
        rating
        urlArr
        notes
      }
      nextToken
    }
  }
`;
