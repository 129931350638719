/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSnaps = /* GraphQL */ `
  mutation CreateSnaps(
    $input: CreateSnapsInput!
    $condition: ModelSnapsConditionInput
  ) {
    createSnaps(input: $input, condition: $condition) {
      id
      __typename
      createdAt
      updatedAt
      name
      species
      wiki
      featured
      captured
      captureDt
      rating
      urlArr
      notes
    }
  }
`;
export const updateSnaps = /* GraphQL */ `
  mutation UpdateSnaps(
    $input: UpdateSnapsInput!
    $condition: ModelSnapsConditionInput
  ) {
    updateSnaps(input: $input, condition: $condition) {
      id
      __typename
      createdAt
      updatedAt
      name
      species
      wiki
      featured
      captured
      captureDt
      rating
      urlArr
      notes
    }
  }
`;
export const deleteSnaps = /* GraphQL */ `
  mutation DeleteSnaps(
    $input: DeleteSnapsInput!
    $condition: ModelSnapsConditionInput
  ) {
    deleteSnaps(input: $input, condition: $condition) {
      id
      __typename
      createdAt
      updatedAt
      name
      species
      wiki
      featured
      captured
      captureDt
      rating
      urlArr
      notes
    }
  }
`;
