import * as React from 'react';
import TextField from '@mui/material/TextField';

import {ReactComponent as Ball} from './../icons/Ball.svg';
import {ReactComponent as BallGrey} from './../icons/BallGrey.svg';

import { Rating } from "@mui/material";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { API } from 'aws-amplify';
import { createSnaps as createsnapMutation} from '../graphql/mutations';
import { getDate } from 'date-fns';
import moment from 'moment';

class AppendEntry extends React.Component {
  constructor(props) {
    super(props);

    var now = moment().milliseconds;
    this.state = {
      // Default form values are hardcoded below
      name: "",
      species: "",
      wiki: '',
      featured: true,
      captured: false,
      captureDt: moment().unix(),
      rating: 0,
      urlArr: [""],
      notes: ''
    };

    const initialFormState = {     }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleAppend = this.handleAppend.bind(this);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleDialogClose () {
    this.props.setDialogOpen(false); // Use the prop.
  };

  handleAppend () {
    //push to an API
    if (!this.state.name || !this.state.notes) return;
    //console.log(this.state);
    API.graphql({ query: createsnapMutation, variables: { input: this.state } });
    
    this.setState({
      // RESET TO NOTHING
      name: "",
      species: "",
      wiki: '',
      featured: true,
      captured: false,
      captureDt: moment().unix(),
      rating: 0,
      urlArr: [""],
      notes: ''
    })
    // Close Dialog
    this.props.setDialogOpen(false); // Use the prop.
  };

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: value
    });
  }

  setDateSeen(dt) {
    this.setState({
      captureDt: moment(dt, "MM/DD/YYYY").unix()
    })
  }

  render() {
    //console.log(this.state);
    return (
      <Dialog open={this.props.dlg}>
        <DialogContent>
          <DialogTitle><BallGrey /> NEW <BallGrey /></DialogTitle>
            <DialogContentText>
              <Ball/> <BallGrey /> <Ball/>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="string"
              fullWidth
              variant="standard"
              name="name"
              onChange={this.handleInputChange}
              value={this.state.name} 
            />
            <TextField
              margin="dense"
              id="species"
              label="Species"
              type="string"
              fullWidth
              variant="standard"
              name="species"
              onChange={this.handleInputChange}
              value={this.state.species} 
            />
            <TextField
              margin="dense"
              id="caption"
              label="Caption"
              type="string"
              fullWidth
              variant="standard"
              name="notes"
              onChange={this.handleInputChange}
              value={this.state.notes} 
            />
            <br/><br/>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="captureDt"
                label="Date Seen"
                renderInput={(params) => <TextField {...params} />}
                onChange={(newValue) => {
                  this.setDateSeen(newValue);
                }}
                value={moment.unix(this.state.captureDt).format("MM/DD/YYYY")}
              />
            </LocalizationProvider>
            <br/><br/>
            <Rating 
              name="rating"
              defaultValue={0} 
              precision={0.5} //value={entry.rating}
              onChange={this.handleInputChange}
              value={this.state.rating} 
              />
          
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAppend}>ADD</Button>
            <Button onClick={this.handleDialogClose}>CANCEL</Button>
          </DialogActions>   
        </Dialog>
    );
  }
}

export default AppendEntry;