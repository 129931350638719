import React from "react";
import Box from '@mui/material/Box';
import ViewEntry from "./ViewEntry"
import InfiniteScroll from "react-infinite-scroller";

import MuiDivider from '@mui/material/Divider';

class InfiniteScrollView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      allposts: [],
      posts: [],
      hasMore: true,
      curpage: 0,
      pagesize: 4,
      totalPage: 0,
      total: 0
    };

    //console.log("constructor - STATE",this.state)
    //console.log("constructor - PROPS",this.state)
  }

componentDidUpdate()
{
  //console.log("componentDidUpdate - STATE",this.state)
  //console.log("componentDidUpdate - PROPS",this.props)

  if (this.props.data.length > 1 && this.state.allposts.length == 0) {
    //this.setState({ allposts: this.props.data });
    var snaps = this.props.data;
      
      let curpage = this.state.curpage;
      let posts = snaps.slice(
        curpage * this.state.pagesize,
        (curpage + 1) * this.state.pagesize
      );
      this.setState({
        allposts: snaps,
        posts: posts,
        total: snaps.length,
        totalPage: Math.ceil(snaps.length / this.state.pagesize)
      });
  }
}
  
  componentDidMount() {
      var snaps = this.props.data;
      
      let curpage = this.state.curpage;
      let posts = snaps.slice(
        curpage * this.state.pagesize,
        (curpage + 1) * this.state.pagesize
      );
      this.setState({
        allposts: snaps,
        posts: posts,
        total: snaps.length,
        totalPage: Math.ceil(snaps.length / this.state.pagesize)
      });
      //console.log("componentDidMount - STATE",this.state)
      //console.log("componentDidMount - PROPS",this.props)
  }

  loadmoreItem() {
    if (this.state.curpage + 1 < this.state.totalPage) {
      let curpage =
        this.state.curpage < this.state.totalPage
          ? this.state.curpage + 1
          : this.state.curpage;
      let posts = this.state.allposts.slice(
        0,
        (curpage + 1) * this.state.pagesize
      );
      this.setState({ posts: posts, curpage });
    } else {
      this.setState({ hasMore: false });
    }

    //console.log("loadmoreItem - STATE",this.state)
    //console.log("loadmoreItem - PROPS",this.props)
  }

  render() {

    //console.log("render - STATE",this.state)
    //console.log("render - PROPS",this.props)
    if (this.state.posts.length === 0) return <h1>loading...</h1>;
    else {
      return (
        <div>
          <Table
            hasMore={this.state.hasMore}
            posts={this.state.posts}
            loadmoreItem={this.loadmoreItem.bind(this)}
          />
        </div>
      );
    }
  }
}

export default InfiniteScrollView;

const Table = props => {
  //console.log("props: ", props);
  return (
    <React.Fragment>
      <div style={{  overflow: "auto" }}>
        <InfiniteScroll
          pageStart={0}
          loadMore={props.loadmoreItem}
          hasMore={props.hasMore}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
          //useWindow={false}
          threshold={350}
        >
            {props.posts.map(snap => {
              return (
                <Box key={snap.id} component="span" m={1}>
                  <ViewEntry entry={snap} />
                  <MuiDivider/>
                </Box>
              );
            })}

        </InfiniteScroll>
      </div>
    </React.Fragment>
  );
};
